<template>
  <button
    type="button"
    :aria-label="$t('Open search panel')"
    class="bg-cl-transparent brdr-none inline-flex"
    @click="toggleSearchpanel"
    data-testid="openSearchPanel"
  >
    <i class="icon-search cl-light-gray icon-size" />
  </button>
</template>

<script>
import SearchIcon from '@vue-storefront/core/compatibility/components/blocks/Header/SearchIcon';

export default {
  mixins: [SearchIcon]
};
</script>

<style lang="scss" scoped>
.icon-size {
  font-size: 32px;
  @media (max-width: 7678px) {
    font-size: 22px;
  }
}
</style>
